import '@/app/app.css';
import './not-found.css';

import { AlsoBuy } from '@/features/also-buy/also-buy';

import '@/app/app';

window.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.js-also-buy').forEach((element) => new AlsoBuy(element));
});
